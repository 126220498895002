import * as React from 'react';
import {JoinManagerType} from "../../types/JoinManagerType";
import {useOutletContext, useParams} from "react-router-dom";
import {LinearProgress} from "@mui/material";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
    fetchJoinManager,
    insertJoinManager, insertOrUpdateSelectionRole,
} from "../../api/QueryService";
import {Server} from "../../types/Server";
import SaveButton from "../../components/SaveButton";
import {InputTextField} from "../../components/form-components/InputTextField";
import {SingleSelectField} from "../../components/form-components/SingleSelectField";
import {FormSwitch} from "../../components/form-components/FormSwitch"
import {MultiSelectField} from "../../components/form-components/MultiSelectFIeld";
import {useFormik} from "formik";
import * as Yup from "yup";

function JoinManager() {

    const {serverId} = useParams()
    const {data, isLoading} = useQuery('join-manager', () => fetchJoinManager(serverId ?? "0"))
    const servers: Server[] = useOutletContext();
    const matchedServer = servers.find(server => server.id === serverId);

    if (data === undefined || isLoading || matchedServer === undefined) {
        return <LinearProgress/>
    }
    return <Child query={data} matchedServer={matchedServer}/>
}

const Child = (data: { query: JoinManagerType, matchedServer: Server }) => {
    const queryClient = useQueryClient();

    const mutateGeneral = useMutation({
        mutationFn: (joinManagerData: JoinManagerType) => insertJoinManager(data.matchedServer.id ?? "0", joinManagerData),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["join-manager"]}).then(_r => {
                formik.setValues(formik.initialValues)
            })
        }
    })

    const ValidationSchema = Yup.object().shape({
        message: Yup.object().shape({
            channel: Yup.string().required('Required'),
            message: Yup.string().min(2, 'Too short!').required(),
            theme: Yup.string().required()
        })
    });

    const formik = useFormik({
        validationSchema: ValidationSchema,
        initialValues: {
            message: {
                channel: data.query.message.channel,
                message: data.query.message.message,
                status: data.query.message.status,
                theme: data.query.message.theme
            },
            joinRole: {
                roles: data.query.joinRole.roles
            },
            verify: {
                status: data.query.verify.status,
                roles: data.query.verify.roles
            }
        },
        onSubmit: value => {
            mutateGeneral.mutate(value)
        },
        enableReinitialize: true,
    })

    return (
        <div>
            <h1 className="text-[32px] prose text-gray font-semibold">JoinManager</h1>
            <p>By adding an embed code to a message you can force a custom embed!</p>
            <div className="flex flex-col justify-center gap-10 mt-4 lg:w-[625px] xl:w-[750px]">
                <Reception currentServer={data.matchedServer}
                           dataState={formik.values} setData={(key, value) => formik.setFieldValue(key, value)}/>
                <AutoRole currentServer={data.matchedServer}
                          dataState={formik.values} setData={(key, value) => formik.setFieldValue(key, value)}/>
                <Verification currentServer={data.matchedServer}
                              dataState={formik.values} setData={(key, value) => formik.setFieldValue(key, value)}/>
            </div>
            <SaveButton onClick={() => formik.handleSubmit()} isOpen={formik.isValid && formik.dirty}/>
        </div>
    )
}

const Reception = (props: {
    currentServer: Server
    dataState: JoinManagerType,
    setData: (key: string, value: any) => void
}) => {
    interface IOption {
        name: string
        id: string
        category: string
    }

    const themes: IOption[] = [
        {
            name: "Dark",
            id: "dark",
            category: "Classic"
        },
        {
            name: "Light",
            id: "light",
            category: "Classic"
        },
        {
            name: "Galaxy",
            id: "galaxy",
            category: "Classic"
        },
    ]

    return (
        <div id="reception"
             className={`bg-sidebar-black rounded px-4 py-4 border-light-blue border-2`}>
            <div className="flex items-center justify-between gap-4">
                <h3 className="prose-2xl prose text-white font-bold">Reception</h3>
                <FormSwitch checked={props.dataState.message.status} onChange={(_e: any, value: boolean) => {
                    props.setData("message.status", value)
                }}/>
            </div>

            {props.dataState.message.status &&
                <div className="pb-4">
                    <hr className="text-grayNew w-full border-t-2 mt-6 mb-10"/>
                    <div className="flex flex-col justify-center gap-10">
                        <InputTextField
                            fullWidth
                            label="Message"
                            rows={3}
                            value={props.dataState.message.message}
                            helperText={"%user% | %server%"}
                            onChange={(e) => {
                                props.setData("message.message", e.target.value)
                            }}
                        />
                        <SingleSelectField
                            text={"Select a channel"}
                            value={props.dataState.message.channel}
                            optionValues={props.currentServer.textChannels}
                            onChange={(_e: any, value: IOption | null) => {
                                if (value !== null) {
                                    props.setData("message.channel", value.id)
                                }
                            }}/>

                        <SingleSelectField
                            text={"Select a theme"}
                            value={props.dataState.message.theme}
                            optionValues={themes}
                            onChange={(_e: any, value: IOption | null) => {
                                if (value !== null) {
                                    props.setData("message.theme", value.id)
                                }
                            }}/>
                    </div>
                </div>
            }

        </div>
    )
}
const AutoRole = (props: {
    currentServer: Server
    dataState: JoinManagerType,
    setData: (key: string, value: any) => void
}) => {
    interface IOption {
        name: string
        id: string
    }

    return (
        <div id="auto-roles"
             className={`bg-sidebar-black rounded px-4 py-4 border-light-blue border-2`}>
            <div className="flex items-center justify-between gap-4">
                <h3 className="prose-2xl prose text-white font-bold">Auto Roles</h3>
            </div>

            <div className="pb-4">
                <hr className="text-grayNew w-full border-t-2 mt-6 mb-10"/>
                <div className="flex flex-col justify-center gap-10">
                    <MultiSelectField
                        text={"Select some roles"}
                        value={props.dataState.joinRole.roles}
                        optionValues={props.currentServer.roles}
                        onChange={(_e: any, value: IOption[]) => {
                            if (value !== null) {
                                props.setData("joinRole.roles", value.map(v => v.id))
                            }
                        }}/>
                </div>
            </div>

        </div>
    )
}

const Verification = (props: {
    currentServer: Server
    dataState: JoinManagerType,
    setData: (key: string, value: any) => void
}) => {

    interface IOption {
        name: string
        id: string
    }

    return (
        <div id="reception"
             className={`bg-sidebar-black rounded px-4 py-4 border-light-blue border-2`}>
            <div className="flex items-center justify-between gap-4">
                <h3 className="prose-2xl prose text-white font-bold">Verification</h3>
                <FormSwitch checked={props.dataState.verify.status} onChange={(_e: any, value: boolean) => {
                    props.setData("verify.status", value)
                }}/>
            </div>

            {props.dataState.verify.status &&
                <div className="pb-4">
                    <hr className="text-grayNew w-full border-t-2 mt-6 mb-10"/>
                    <div className="flex flex-col justify-center gap-10">
                        <MultiSelectField
                            text={"Select some roles"}
                            value={props.dataState.verify.roles}
                            optionValues={props.currentServer.roles}
                            onChange={(_e: any, value: IOption[]) => {
                                if (value !== null) {
                                    props.setData("verify.roles", value.map(v => v.id))
                                }
                            }}/>
                    </div>
                </div>
            }

        </div>
    )
}

export default JoinManager;
