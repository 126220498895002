import React from 'react'
import {Autocomplete, TextField} from "@mui/material";
import {useQuery} from "react-query";
import {fetchYouTubeChannelByName} from "../../api/QueryService";
import Box from "@mui/material/Box";
import {SocialChannelType} from "../../types/SocialChannelType";


export const YouTubeChannelSearchField = (props: {
    text: string,
    onChange: (value: SocialChannelType) => void,
    value: SocialChannelType,
    error?: boolean
}) => {
    const [inputValue, setInputValue] = React.useState('');
    const {data, isLoading} = useQuery({
        queryKey: ["find-twitch", inputValue],
        queryFn: () => fetchYouTubeChannelByName(inputValue)
    })

    if (!data?.some((fetchedData) => fetchedData.id === props.value.id)) {
        data?.push(props.value)
    }

    return (
        <div>
            <Autocomplete
                sx={{minWidth: "280px"}}
                onChange={(_event: any, value: SocialChannelType | null) => {
                    if (value !== null) {
                        props.onChange(value);
                    }
                }}
                value={props.value}
                inputValue={inputValue}
                onInputChange={(_event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                options={data ?? []}
                getOptionLabel={(option) => option.name}
                filterOptions={(x) => x}
                disableCloseOnSelect={true}
                loading={isLoading}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                renderOption={(props, option) => (
                    <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                        <img loading={"lazy"}
                             width="20px"
                             alt={option.name}
                             src={option.icon}
                        />
                        {option.name}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField error={props.error} {...params} label={props.text}/>
                )}
            />
        </div>
    )
}
