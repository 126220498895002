import React from 'react'

export const SubmitButtons = (props: { valid: boolean, onSubmit: () => void, onReset: () => void }) => {

    return (
        <div className="flex items-center justify-end gap-4">
            <button onClick={props.onReset}
                    className={`prose prose-base border-2 border-light-blue text-white rounded py-1 px-6 ease-in-out duration-150`}
                    id="action-btn">Reset
            </button>
            <button onClick={props.onSubmit}
                    className={`${(props.valid) ? "bg-light-blue hover:bg-white hover:text-light-blue" : ""} prose prose-base border-2 text-white rounded py-1 px-6 ease-in-out duration-150`}
                    id="action-btn">Save
            </button>
        </div>
    )
}
