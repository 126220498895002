import {Server} from "../types/Server";
import {BiEdit, BiTrash} from "react-icons/bi";
import * as React from "react";
import {SupportType} from "../types/SupportType";

function SupportOptionListDisplay(props: {
    onDelete: (name: string) => void,
    label: string,
    data: SupportType,
    currentServer: Server,
    onChange: (value: any) => void
}) {
    return (
        <div className="mt-4 bg-sidebar-black p-5 rounded">
            <div className="flex items-center justify-between gap-4">
                <h3 className="prose-xl prose font-semibold text-white opacity-50">{props.label}</h3>
                <span
                    className="prose-xl prose font-semibold text-white opacity-50">{props.data.supportData.length}/∞</span>
            </div>

            <hr className="mt-3.5 mb-6 border-t-2 border-white opacity-50"/>

            <div className="flex flex-col justify-center gap-4">
                {props.data.supportData.map((data) => {
                    return (
                        <div
                            key={`support-option-${data.name}`}
                            className="bg-cardGray rounded py-4 px-3 flex gap-4 justify-between items-center">
                            <div className="flex items-center gap-4">
                                <h4 className="text-white prose prose-xl">{data.name}</h4>
                                <p className="text-white opacity-50 prose-sm">
                                    {props.currentServer.textChannels.find((vc) => vc.id === data.logsChannel)?.name}
                                </p>
                            </div>
                            <div className="flex items-center gap-4">
                                <BiTrash className="cursor-pointer text-white"
                                         onClick={() => props.onDelete(data.name)} size={"1.5rem"}/>
                                <BiEdit className="cursor-pointer text-white" onClick={() => props.onChange(data)}
                                        size={"1.5rem"}/>
                            </div>
                        </div>
                    )
                })}

            </div>
        </div>
    )
}

export default SupportOptionListDisplay;
