import * as React from 'react';
import {useOutletContext, useParams} from "react-router-dom";
import {Server} from "../../types/Server";
import {LinearProgress} from "@mui/material";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {fetchEmbedsFromServer, insertOrUpdateEmbed, deleteEmbed} from "../../api/QueryService";
import {useState} from "react";
import * as Yup from "yup";
import {useFormik} from "formik";
import {AiOutlinePlus} from "react-icons/ai";
import {InputTextField} from "../../components/form-components/InputTextField";
import {EmbedManagerType} from "../../types/EmbedManagerType";
import EmbedManagerListDisplay from "../../components/embed-manger/EmbedManagerListDisplay";
import {BlockPicker} from "react-color"
import DiscordEmbedPreview from "../../components/embed-manger/DiscordEmbedPreview";
import EmbedManagerFields from "../../components/embed-manger/EmbedManagerFields";
import EmbedManagerAuthor from "../../components/embed-manger/EmbedManagerAuthor";
import EmbedManagerFooter from "../../components/embed-manger/EmbedManagerFooter";
import {SubmitButtons} from "../../components/form-components/SubmitButtons";

export default function EmbedManager() {

    const {serverId} = useParams()
    const {data, isLoading} = useQuery({
        queryKey: ["embed-manager-embeds"],
        queryFn: () => fetchEmbedsFromServer(serverId ?? "0")
    })

    const servers: Server[] = useOutletContext();
    const matchedServer = servers.find(server => server.id === serverId);

    if (data === undefined || isLoading || matchedServer === undefined) {
        return <LinearProgress/>
    }

    return <Child data={data} currentServer={matchedServer}/>
}

const Child = (props: { data: EmbedManagerType[], currentServer: Server }) => {
    const [open, setOpen] = useState(false)

    const queryClient = useQueryClient();
    const updateEmbed = useMutation({
        mutationFn: (data: EmbedManagerType) => insertOrUpdateEmbed(props.currentServer.id ?? "0", data),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["embed-manager-embeds"]}).then(_r => {
            });
            formik.setValues(formik.initialValues)
            setOpen(false)
        }
    })
    const removeEmbed = useMutation({
        mutationFn: (uuid: string) => deleteEmbed(props.currentServer.id ?? "0", uuid),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["embed-manager-embeds"]}).then(_r => {
            })
            formik.setValues(formik.initialValues)
            setOpen(false)
        }
    })

    const ValidationSchema = Yup.object().shape({
        uuid: Yup.string(),
        name: Yup.string()
            .min(2, 'Too Short!')
            .max(16, 'Too Long!')
            .required('Required'),
        embed: Yup.object().shape({
            title: Yup.string().min(2, 'Too Short!').max(256, 'Too Long!').required('Required'),
            description: Yup.string().max(4096, 'Too Long!'),
            fields: Yup.array().of(Yup.object().shape({
                name: Yup.string()
                    .max(256, 'Too Long!'),
                value: Yup.string()
                    .max(1024, 'Too Long!')
            })).max(25, "To many fields"),
            footer: Yup.object().shape({
                text: Yup.string()
                    .max(2048, 'Too Long!')
            }),
            author: Yup.object().shape({
                name: Yup.string()
                    .max(256, 'Too Long!')
            })
        }),
    });
    const formik = useFormik({
        validationSchema: ValidationSchema,
        initialValues: {
            uuid: "0", name: "", embed: {
                title: "",
                description: "",
                color: "",
                fields: [],
                timeStamp: false,
                image: "",
                thumbnail: "",
                author: {name: "", url: "", icon: ""},
                footer: {text: "", icon: ""},
            }
        } as EmbedManagerType,
        onSubmit: value => updateEmbed.mutate(value),
    })


    return (
        <div>
            <h1 className="text-[32px] prose text-gray font-semibold">Embed Manager</h1>
            <p className="max-w-[750px] prose-sm">Here you can create your own embeds. After creating your own embed, you can
                copy the code and enter it into various features. The message will then be replaced by the embed! The
                UUID is not server dependent so you can share it. EmbedManager also supports, among other things, the
                <span className="ml-1 bg-grayNew p-2 rounded text-white">/say</span> command. Click the UUID in the middle of the card to copy it</p>
            <div className="mt-4 lg:w-[625px] xl:w-[750px]">
                <div
                    className={`${open ? "border-b-cardGray" : ""} border-2 rounded border-light-blue bg-sidebar-black p-5 flex items-center justify-between gap-4`}>
                    <h2 className="prose prose-2xl text-white font-bold">Generate a new embed</h2>
                    <AiOutlinePlus className="text-white cursor-pointer" size={"2rem"} onClick={() => setOpen(!open)}
                    />
                </div>
                <div>
                    {open &&
                        <div className="bg-sidebar-black p-5">
                            <div className="mt-5">
                                <InputTextField error={Boolean(formik.errors.name)} helperText={formik.errors.name}
                                                id="name" label={"Name"} rows={1}
                                                value={formik.values.name}
                                                onChange={(e) => formik.setFieldValue("name", e.target.value)}/>
                            </div>
                            <hr className="border-b-2 border-grayNew my-5"/>
                            <div className="flex flex-col justify-center gap-6">
                                <InputTextField
                                    fullWidth
                                    error={Boolean(formik.errors.embed?.title)}
                                    helperText={formik.errors.embed?.title}
                                    id="title" label={"Title"} rows={1}
                                    value={formik.values.embed.title}
                                    onChange={(e) => formik.setFieldValue("embed.title", e.target.value)}/>

                                <InputTextField
                                    fullWidth
                                    error={Boolean(formik.errors.embed?.description)}
                                    helperText={formik.errors.embed?.description}
                                    id="description" label={"Description"} rows={6}
                                    value={formik.values.embed.description}
                                    onChange={(e) => formik.setFieldValue("embed.description", e.target.value)}/>
                                <div className="flex gap-8 items-center flex-wrap">
                                    <BlockPicker color={formik.values.embed.color} triangle={"hide"}
                                                 onChange={(something) => formik.setFieldValue("embed.color", something.hex)}/>
                                    <div className="flex flex-col justify-center gap-6">
                                        <InputTextField
                                            id="image" label={"Image"} rows={1}
                                            value={formik.values.embed.image}
                                            onChange={(e) => formik.setFieldValue("embed.image", e.target.value)}/>

                                        <InputTextField
                                            id="thumbnail" label={"Thumbnail"} rows={1}
                                            value={formik.values.embed.thumbnail}
                                            onChange={(e) => formik.setFieldValue("embed.thumbnail", e.target.value)}/>
                                    </div>
                                </div>

                                <div>
                                    <EmbedManagerFields fields={formik.values.embed.fields}
                                                        onAdd={() => {
                                                            let array = formik.values.embed.fields;
                                                            array.push({
                                                                name: "",
                                                                value: "",
                                                                inline: false
                                                            })
                                                            formik.setFieldValue("embed.fields", array)
                                                        }}
                                                        onRemove={(index) => {
                                                            let array = formik.values.embed.fields;
                                                            array.splice(index, 1)
                                                            formik.setFieldValue("embed.fields", array)
                                                        }}
                                                        onChange={(index, key, value) => {
                                                            formik.setFieldValue(`embed.fields[${index}].${key}`, value)
                                                        }}
                                    />
                                    <EmbedManagerAuthor type={formik.values}
                                                        onNameChange={(value) => formik.setFieldValue("embed.author.name", value)}
                                                        onIconChange={(value) => formik.setFieldValue("embed.author.icon", value)}
                                                        onUrlChange={(value) => formik.setFieldValue("embed.author.url", value)}
                                    />

                                    <EmbedManagerFooter type={formik.values}
                                                        onTimeStampChange={(value) => formik.setFieldValue("embed.timeStamp", value)}
                                                        onTextChange={(value) => formik.setFieldValue("embed.footer.text", value)}
                                                        onIconChange={(value) => formik.setFieldValue("embed.footer.icon", value)}
                                    />
                                </div>

                            </div>
                            <div className="my-3.5">
                                <p className="prose-base prose text-gray">Preview (Alpha)</p>
                                <DiscordEmbedPreview embed={formik.values}/>
                            </div>
                            <SubmitButtons valid={formik.isValid && formik.dirty} onSubmit={formik.submitForm}
                                           onReset={() => formik.setValues(formik.initialValues)}/>
                        </div>
                    }
                </div>
                <EmbedManagerListDisplay label={"Embeds"} data={props.data} currentServer={props.currentServer}
                                         onDelete={(value) => removeEmbed.mutate(value)}
                                         onChange={(value: EmbedManagerType) => {
                                             formik.setValues(value)
                                             setOpen(true)
                                         }}/>
            </div>
        </div>
    )
}
