import {useState} from "react";
import {AiOutlinePlus} from "react-icons/ai";
import * as React from "react";
import {InputTextField} from "../form-components/InputTextField";
import {EmbedManagerType} from "../../types/EmbedManagerType";

function EmbedManagerAuthor(props: {
    type: EmbedManagerType,
    onNameChange: (value: string) => void,
    onIconChange: (value: string) => void,
    onUrlChange: (value: string) => void
}) {

    const [open, setOpen] = useState(false)

    return (
        <div className="border-b-2 border-t-1 border-grayNew py-4">
            <div className="flex justify-between items-center">
                <h4 className="prose-base prose text-white">Author</h4>
                <AiOutlinePlus className="cursor-pointer text-white" size={20} onClick={() => setOpen(!open)}/>
            </div>

            {open &&
                <div className="mt-4 flex flex-col justify-center gap-4">
                    <InputTextField
                        id="author.name" label={"Name"} rows={1}
                        value={props.type.embed.author.name}
                        onChange={(e) => props.onNameChange(e.target.value)}/>

                    <InputTextField
                        id="author.icon" label={"Icon"} rows={1}
                        value={props.type.embed.author.icon}
                        onChange={(e) => props.onIconChange(e.target.value)}/>

                    <InputTextField
                        id="author.url" label={"Url"} rows={1}
                        value={props.type.embed.author.url}
                        onChange={(e) => props.onUrlChange(e.target.value)}/>
                </div>
            }

        </div>
    )
}

export default EmbedManagerAuthor;
