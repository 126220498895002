import React, {ReactElement} from 'react';
import {Routes, Route, Outlet, useParams} from 'react-router-dom';
import {CircularProgress, createTheme, ThemeProvider} from "@mui/material";
import {QueryClientProvider, useQuery} from "react-query";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Sidebar from "./components/sidebar/Sidebar";

import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Imprint from "./pages/Imprint";
import JoinManager from "./pages/dashboard/JoinManager";
import TempChannel from "./pages/dashboard/TempChannel";
import Twitch from "./pages/dashboard/Twitch";
import PageNotFound from "./pages/PageNotFound";
import General from "./pages/dashboard/General";
import {QueryClient} from "react-query";
import {fetchServers} from "./api/QueryService";
import Support from "./pages/dashboard/Support";
import EmbedManager from "./pages/dashboard/EmbedManager";
import YouTube from "./pages/dashboard/YouTube";
import SelectionRoles from "./pages/dashboard/SelectionRoles";
import CustomEmojis from "./pages/dashboard/CustomEmojis";


const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#71B8FC'
        },
        secondary: {
            main: '#edf2ff'
        },
        background: {
            default: '#1a1f3a'
        }
    }
})

const queryClient = new QueryClient()

function App() {
    return (
        <React.Suspense fallback={Home}>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                        <Routes>
                            <Route path="*" element={<PageNotFound/>}/>
                            <Route index element={<NavBar element={<Home/>}/>}/>
                            {/**<Route path='/verification/:id' element={<Standard element={<Verification/>}/>}/>**/}
                            <Route path='/dashboard' element={<NavBar element={<Dashboard/>}/>}/>
                            <Route path='/imprint' element={<NavBar element={<Imprint/>}/>}/>
                            <Route path="/dashboard/:serverId" element={<ControlPanel/>}>
                                <Route index element={<General/>}/>
                                <Route path='join-manager' element={<JoinManager/>}/>
                                <Route path='temp-channel' element={<TempChannel/>}/>
                                <Route path="embed-manager" element={<EmbedManager/>}/>
                                <Route path='twitch' element={<Twitch/>}/>
                                <Route path='youtube' element={<YouTube/>}/>
                                <Route path='support' element={<Support/>}/>
                                <Route path='selection-roles' element={<SelectionRoles/>}/>
                                {/*<Route path='custom-emojis' element={<CustomEmojis/>}/>*/}
                            </Route>
                        </Routes>
                </QueryClientProvider>
            </ThemeProvider>
        </React.Suspense>
    );
}

function NavBar(props: { element: ReactElement }) {
    return (
        <>
            <Header/>
            {props.element}
            <Footer/>
        </>
    )
}

function ControlPanel() {

    const {serverId} = useParams()
    const {data} = useQuery('servers', fetchServers)
    const matchedServer = data?.find(server => server.id === serverId);


    return (
        <div className="flex md:pl-[300px]">
            <Sidebar/>
            <div className="py-8 px-3 md:px-8">
                <div className="overflow-y-scroll">
                    {data === undefined || matchedServer === undefined ? <div className="flex justify-center items-center p-7"><CircularProgress/></div> : <Outlet context={data}/>}
                </div>
            </div>
        </div>
    )
}

export default App;
