import * as React from "react";
import {useOutletContext, useParams} from "react-router-dom";
import {LinearProgress} from "@mui/material";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
    deleteSelectionRole, fetchSelectionRoles, insertOrUpdateSelectionRole,
} from "../../api/QueryService";
import {Server} from "../../types/Server";
import {useState} from "react";
import {useFormik} from "formik";
import {AiOutlinePlus} from "react-icons/ai";
import {InputTextField} from "../../components/form-components/InputTextField";
import * as Yup from 'yup';
import {SubmitButtons} from "../../components/form-components/SubmitButtons";
import {SelectionRoleType} from "../../types/SelectionRoleType";
import {RoleSingleSelectField} from "../../components/form-components/RoleSingleSelectField";
import SelectionRoleListDisplay from "../../components/SelectionRoleListDisplay";
import EmojiPicker, {EmojiStyle, Theme} from "emoji-picker-react";

function SelectionRoles() {

    const {serverId} = useParams()
    const servers: Server[] = useOutletContext();
    const matchedServer = servers.find(server => server.id === serverId);

    const {data, isLoading} = useQuery({
        queryKey: ["selectionRolesFetch"],
        queryFn: () => fetchSelectionRoles(serverId ?? "0")
    })

    if (data === undefined || isLoading || matchedServer === undefined) {
        return <LinearProgress/>
    }

    return <Child data={data} currentServer={matchedServer}/>
}

const Child = (props: { data: SelectionRoleType[], currentServer: Server }) => {

    const queryClient = useQueryClient();

    const [open, setOpen] = useState(false)
    const [openEmoji, setOpenEmoji] = useState(false)

    const updateSelectionRole = useMutation({
        mutationFn: (data: SelectionRoleType) => insertOrUpdateSelectionRole(props.currentServer.id ?? "0", data),
        onSuccess: () => {
            queryClient.invalidateQueries(["selectionRolesFetch"])
            formik.setValues(formik.initialValues)
        }
    })

    const removeRole = useMutation({
        mutationFn: (value: string) => deleteSelectionRole(props.currentServer.id ?? "0", value),
        onSuccess: () => {
            queryClient.invalidateQueries(["selectionRolesFetch"])
            formik.setValues(formik.initialValues)
        }
    })


    const ValidationSchema = Yup.object().shape({
        name: Yup.string().required(),
        description: Yup.string()
            .min(2, 'Too Short!')
            .max(25, 'Too Long!')
            .required('Required'),
        emoji: Yup.string().required('Required'),
        roleId: Yup.string().required('Required'),
    });

    const formik = useFormik({
        validationSchema: ValidationSchema,
        initialValues: {
            name: '',
            description: '',
            emoji: '',
            roleId: '',
        },
        onSubmit: values => {
            updateSelectionRole.mutate(values);
            setOpen(false);
        },
    })


    return (
        <div>
            <h1 className="text-[32px] prose text-gray font-semibold">Selection Roles</h1>
            <p className="prose-sm">Use <span
                className="bg-grayNew p-2 rounded text-white">/buildroleselection</span> to send the HUB in a specific
                channel</p>
            <div className="mt-4 lg:w-[625px] xl:w-[750px]">
                <div
                    className={`${open ? "border-b-cardGray" : ""} border-2 rounded border-light-blue bg-sidebar-black p-5 flex items-center justify-between gap-4`}>
                    <h2 className="prose prose-2xl text-white font-bold">Add a new role</h2>
                    <AiOutlinePlus className="text-white" onClick={() => setOpen(!open)}
                                   size={"2rem"}/>
                </div>
                <div>
                    {open &&
                        <div className="bg-sidebar-black p-5">
                            <div
                                className="flex flex-wrap justify-left gap-4 mb-5 mt-5">
                                <InputTextField
                                    error={Boolean(formik.errors.name)}
                                    id="name" label={"Name"} rows={1}
                                    value={formik.values.name}
                                    onChange={(e) => formik.setFieldValue("name", e.target.value)}/>

                                <InputTextField
                                    error={Boolean(formik.errors.name)}
                                    id="description" label={"Description"} rows={1}
                                    value={formik.values.description}
                                    onChange={(e) => formik.setFieldValue("description", e.target.value)}/>

                                <div
                                    className="border-2 rounded w-[50px] grid place-content-center h-[50px] border-grayNew"
                                    onClick={() => setOpenEmoji(!openEmoji)}>
                                    <span className="text-2xl">{formik.values.emoji}</span>
                                </div>

                                {openEmoji && <div className="">
                                    <EmojiPicker
                                        theme={Theme.DARK}
                                        width="280px"
                                        emojiStyle={EmojiStyle.TWITTER}
                                        onEmojiClick={(emoji) => {
                                            formik.setFieldValue("emoji", emoji.emoji)
                                        }}/>
                                </div>}

                                <RoleSingleSelectField error={Boolean(formik.errors.roleId)} text={"Role"}
                                                       value={formik.values.roleId}
                                                       optionValues={props.currentServer.roles}
                                                       onChange={(_event, value) => formik.setFieldValue("roleId", value?.id)}/>
                            </div>
                            <SubmitButtons valid={formik.isValid && formik.dirty} onSubmit={formik.submitForm}
                                           onReset={() => formik.setValues(formik.initialValues)}/>
                        </div>
                    }
                </div>
                <SelectionRoleListDisplay label={"Roles"} data={props.data} currentServer={props.currentServer}
                                          onDelete={(value) => removeRole.mutate(value)}/>
            </div>
        </div>
    )
}

export default SelectionRoles
