import React, {useState} from 'react';

import SidebarProfile from "./SidebarProfile";
import SidebarOptions from "./SidebarOptions";
import {CircularProgress} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import {useQuery, useQueryClient} from "react-query";
import {fetchServers, me} from "../../api/QueryService";
import Hamburger from 'hamburger-react'

function Sidebar() {

    const serverQuery = useQuery('servers', fetchServers)
    const userQuery = useQuery("me", me)
    useQueryClient();
    const {serverId} = useParams()
    const matchedServer = serverQuery.data?.find(server => server.id === serverId);
    const [open, setOpen] = useState(true)

    const openMenu = () => {
        setOpen(!open)
    };


    return (
        <div className={"fixed top-0 left-0 z-[99999]"}>
            <div className={`relative h-screen transition-transform duration-300 ${open ? "" : "-translate-x-full md:translate-x-0"} `}>
                <div
                    className={`absolute top-1 bg-sidebar-black rounded left-[calc(100%_+_0.375rem)] visible md:hidden`}>
                    <Hamburger size={25} color="#ffffff" toggled={open} onToggle={openMenu}/>
                </div>

                <div
                    className={`bg-sidebar-black flex gap-6 flex-col items-center w-[300px] h-screen overflow-y-auto text-gray-400 scrollbar-hide rounded py-8 px-5`}>

                    {(matchedServer === undefined || userQuery.isError || userQuery.data === undefined || serverQuery.data === undefined) ?
                        <CircularProgress color="inherit"/> :
                        <>
                            <SidebarProfile
                                avatar={`https://cdn.discordapp.com/icons/${matchedServer.id}/${matchedServer.icon}`}
                                name={userQuery.data.userName + "#" + userQuery.data.userDiscriminator}
                                server={matchedServer.name || "Loading"} servers={serverQuery.data}
                                serverId={matchedServer.id}/>
                        </>
                    }

                    <hr className="text-grayNew w-full border-t-2 mt-2.5"/>

                    <SidebarOptions/>


                    <Link to="/dashboard"
                          className=" rounded flex items-center justify-center w-full py-3 mt-auto bg-light-blue text-white hover:bg-white ease-in-out duration-150 hover:text-light-blue">
                        <span className="ml-2 prose-base">Dashboard</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;
