import React from 'react'


function Imprint() {

    return (
        <>
            <div className="my-[75px] container text-white font-semibold">
                <h1 className="prose-2xl text-white font-bold">Imprint</h1>
                <p>Name: Marvin Becker</p>
                <p>E-Mail: marvin.becker@alphaexe.dev</p>
                <p>78315 Radolfzell am Bodensee Germany</p>
                <p>Address only on request</p>
            </div>
            <div className="my-[50px] flex flex-col gap-3.5 container text-white font-semibold">
                <h1 className="prose-2xl text-white font-bold">Privacy Policy</h1>
                <div>
                    <h2>1. General Information</h2>
                    <p>The protection of your data is very important to us. This privacy policy informs you about the
                        data
                        we collect, how we use it, and your rights regarding your data.</p>
                </div>

                <div><h2>2. Responsible Party</h2>
                    <p>The responsible party for data processing is:<br/>
                        Marvin Becker<br/>
                        marvin.becker@alphaexe.dev</p>
                </div>

                <div><h2>3. Collection and Storage of Data</h2>
                    <p>We collect and store only the settings of users of our services. These are non-personal data that
                        do
                        not allow any conclusions about your identity. We do not store personal data such as names,
                        email
                        addresses, or other contact information.</p>
                </div>

                <div>
                    <h2>4. Purpose of Data Collection</h2>
                    <p>The storage of user settings is solely for the purpose of improving the use of our services and
                        providing you with a personalized user experience.</p>
                </div>

                <div><h2>5. Data Sharing</h2>
                    <p>Your data will not be shared with third parties. There is no transmission to external service
                        providers or other parties.</p></div>

                <div><h2>6. User Rights</h2>
                    <p>As a user, you have the right to receive information about the data we have stored about you and,
                        if
                        necessary, to request the correction or deletion of this data. Upon direct request, your data
                        can be
                        deleted immediately. For questions about the collection, processing, or use of your data, or for
                        information, corrections, or deletions, please contact us using the contact information
                        provided.</p></div>

                <div><h2>7. Changes to the Privacy Policy</h2>
                    <p>We reserve the right to change this privacy policy at any time. The current version is always
                        available on our website.</p></div>

                <div><h2>8. Contact</h2>
                    <p>If you have any questions or concerns regarding this privacy policy, you can reach us at the
                        following contact information:<br/>
                        marvin.becker@alphaexe.dev or via Discord Server (preferred)</p></div>

            </div>
        </>
    )
}

export default Imprint;
