import * as React from 'react';
import {HiUserGroup} from "react-icons/hi";
import {Server} from "../../types/Server";
import {useOutletContext, useParams} from "react-router-dom";
import {LinearProgress} from "@mui/material";
import {useMutation, useQuery} from "react-query";
import {fetchGeneral, insertGeneral} from "../../api/QueryService";
import {useState} from "react";
import GeneralCard from "../../components/GeneralCard";
import {GeneralType} from "../../types/GeneralType";
import SaveButton from "../../components/SaveButton";

type Feature = {
    name: string,
    text: string,
    href?: string,
}
type GeneralFeature = {
    key?: keyof GeneralType
    directlyEdit?: boolean
} & Feature


const GENERAL_FEATURES: GeneralFeature[] = [
    {
        name: "Auto Roles",
        text: "Instantly give a role to new members as soon as they join",
        href: "join-manager#auto-role",
    },
    {
        key: "isVerification",
        name: "User Verification",
        text: "Verify that new members on your discord server are humans",
        href: "./join-manager#verify"
    },
    {
        key: "isWelcome",
        name: "Reception",
        text: "Greet every new member with a  custom message and a personalized image",
        href: "./join-manager#reception"
    },
    {
        key: "isSupport",
        name: "Support System ",
        text: "A simple and easy to use support system for your server with infinite custom topics",
        href: "./support"
    },
    {
        key: "isMusic",
        name: "Music",
        text: "Listen to your favourite music with the highest possible bitrate",
        directlyEdit: true
    },
]
const SOCIAL_FEATURES: Feature[] = [
    {
        name: "YouTube Notifications",
        text: "Get a notification every time your favourite youtuber posts a new video or goes live",
        href: "./youtube"
    },
    {
        name: "Twitch Notifications",
        text: "Get a notification every time your favourite twitch streamer goes live",
        href: "./twitch"
    },
]
const MISCELLANEOUS: Feature[] = [
    {
        name: "Temporary Channel",
        text: "Generate a new voice channel with one click and auto-delete it by leaving",
        href: "./temp-channel"
    },
    {
        name: "Selection Roles",
        text: "Build your own selection module for roles and give the opportunity to select your own roles",
        href: "./selection-roles"
    },
    {
        name: "Giveaway",
        text: "Use /giveaway create and /giveaway reroll to manage your giveaways!",
    }
]

export default function General() {

    const {serverId} = useParams()
    const servers: Server[] = useOutletContext();
    const matchedServer = servers.find(server => server.id === serverId);

    const {data, isLoading} = useQuery("general", () => fetchGeneral(serverId ?? "0"))

    if (data === undefined || isLoading || matchedServer === undefined) {
        return <LinearProgress/>
    }

    return <Child query={data} matchedServer={matchedServer}/>
}

const Child = (data: { query: GeneralType, matchedServer: Server }) => {
    const [dataState, setDataState] = useState(data.query);
    const mutateGeneral = useMutation((insertGeneralData: GeneralType) => insertGeneral(data.matchedServer.id ?? "0", insertGeneralData))
    const [canSave, setCanSave] = useState(false);

    const onSubmit = () => {
        mutateGeneral.mutate(dataState)
        setCanSave(false)
    }

    return (
        <>
            <h1 className="text-[32px] prose text-gray font-semibold">Home</h1>

            <div className="bg-sidebar-black flex items-center gap-3 text-gray rounded px-3 w-[200px]">
                <HiUserGroup size="30px"/>
                <h2 className="prose prose-2xl text-gray font-semibold">{data.matchedServer.memberCount}</h2>
            </div>

            <div className="flex items-center gap-3 w-[200px] mt-2">
                <div className="w-[100px] text-gray flex items-center gap-4 bg-sidebar-black rounded px-3">
                    <svg className="boostedTierIcon-3OcERy" aria-hidden="true" role="img" width="16" height="31"
                         viewBox="0 0 6 11">
                        <g fill="currentColor" fillRule="evenodd">
                            <path
                                d="M3 0.625305L0 3.62531V7.62531L3 10.6253L6 7.62531V3.62531L3 0.625305ZM5 7.24531L3 9.24531L1 7.24531V4.04531L3 2.04531L5 4.04531V7.24531Z"></path>
                            <path d="M3.76 4.21526L3 3.45526L2 4.45526V5.97526L3.76 4.21526Z"></path>
                            <path
                                d="M2.28003 7.11532L3.00003 7.83532L4.00003 6.83532V5.39532L2.28003 7.11532Z"></path>
                        </g>
                    </svg>
                    <h3 className="prose prose-2xl text-gray font-semibold">{data.matchedServer.boostLevel}</h3>
                </div>
                <div className="w-[100px] bg-sidebar-black rounded px-3">
                    <h3 className="prose prose-2xl text-green font-semibold">Online</h3>
                </div>
            </div>

            <div className="mt-8">
                <div className="flex flex-wrap items-center justify-center lg:justify-start gap-8">
                    {GENERAL_FEATURES.map((generalFeature) =>
                        (
                            <GeneralCard key={generalFeature.name + "-card"} title={generalFeature.name} text={generalFeature.text}
                                         activated={generalFeature.key !== undefined ? dataState[generalFeature.key] : undefined}
                                         href={generalFeature.href}
                                         onClick={() => {
                                             if (generalFeature.key !== undefined) {
                                                 setDataState({
                                                     ...dataState,
                                                     [generalFeature.key]: !dataState[generalFeature.key]
                                                 })
                                                 setCanSave(true);
                                             }
                                         }}/>
                        ))}
                </div>
                <FeatureCards title="SocialType" features={SOCIAL_FEATURES}/>
                <FeatureCards title="Miscellaneous" features={MISCELLANEOUS}/>
            </div>
            <SaveButton isOpen={canSave} close={() => setCanSave(false)} onClick={onSubmit}/>
        </>
    )
}


const FeatureCards = (props: { title: string, features: Feature[] }) =>
    (
        <>
            <div className="my-8 flex items-center gap-3 ">
                <span className="text-white prose prose-xl font-semibold">{props.title}</span>
                <span className="flex-grow-[1] block h-1 bg-light-blue"></span>
            </div>

            <div className="flex flex-wrap items-center justify-center lg:justify-start gap-8">
                {props.features.map((feature) => {
                    return (
                        <GeneralCard title={feature.name} text={feature.text} href={feature.href}/>
                    )
                })}
            </div>
        </>
    )
