function Footer() {
    return (
        <footer className="bg-[#1C1C1C]">
            <div className="py-6 flex flex-col md:flex-row md:justify-between justify-center items-center container">

                <div>
                    <a href={"/"}>
                        <div className="flex gap-2 justify-center items-center">
                            <img className="h-[30px]" src="/logo.svg" alt="interception-logo"/>
                            <p className="prose-2xl bg-clip-text text-transparent bg-gradient-to-r from-light-blue to-pink">Interception</p>
                        </div>
                    </a>

                    <p className="prose-base leading-normal mt-2 text-gray-400 text-center">
                        © AlphaEXE 2024. <br/>
                        All Rights reserved
                    </p>
                </div>

                <div
                    className="flex dark:text-gray font-medium justify-between gap-10 items-start mt-6 prose-base leading-normal">
                    <ul>
                        <li><h1 className="prose-xl text-light-blue font-bold">General</h1></li>
                        <li><a href="https://discord.gg/SSwXXZz">Support</a></li>
                        <li><a href="https://ko-fi.com/marvn">Donate</a></li>
                        <li><a href="./imprint">Imprint & Privacy Policy</a></li>
                        <li><a className="underline" href="https://discord.com/terms">Terms of Service</a>
                        </li>
                    </ul>

                    <ul>
                        <li><h1 className="prose-xl text-light-blue font-bold">Social</h1></li>
                        <li><a href="https://top.gg/de/bot/723394954193272892">Top.gg</a></li>
                        <li><a href="https://discordbotlist.com/bots/interception">Discord Bots</a></li>
                        <li><a href="https://discord.gg/SSwXXZz">Discord Server</a></li>
                    </ul>
                </div>

            </div>
        </footer>
    )
}

export default Footer;
