import * as React from "react";
import {useOutletContext, useParams} from "react-router-dom";
import {LinearProgress} from "@mui/material";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
    fetchSupport,
    insertSupportData,
} from "../../api/QueryService";
import {Server} from "../../types/Server";
import {useFormik} from "formik";
import {InputTextField} from "../../components/form-components/InputTextField";
import {SingleSelectField} from "../../components/form-components/SingleSelectField";
import * as Yup from 'yup';
import {SupportType} from "../../types/SupportType";
import SupportOption from "../../components/SupportOption";

function Support() {

    const {serverId} = useParams()
    const servers: Server[] = useOutletContext();
    const matchedServer = servers.find(server => server.id === serverId);

    const {data, isLoading} = useQuery({
        queryKey: ["fetchSupport"],
        queryFn: () => fetchSupport(serverId ?? "0")
    })

    if (data === undefined || isLoading || matchedServer === undefined) {
        return <LinearProgress/>
    }

    return <Child data={data} currentServer={matchedServer}/>
}

const Child = (props: { data: SupportType, currentServer: Server }) => {
    const queryClient = useQueryClient();
    const updateSupportOption = useMutation({
        mutationFn: (data: any) => insertSupportData(props.currentServer.id ?? "0", data),
        onSuccess: () => queryClient.invalidateQueries(["fetchSupport"])
    })

    const ValidationSchema = Yup.object().shape({
        channel: Yup.string()
            .required('Required'),
        message: Yup.string()
            .min(2, 'Too Short!')
            .required('Required'),
        ticketName: Yup.string().min(2, 'Too Short!').max(16, 'Too Long!').required('Required'),
    });

    const formik = useFormik({
        validationSchema: ValidationSchema,
        initialValues: {
            channel: props.data.channel,
            message: props.data.message,
            ticketName: props.data.ticketName,
            supportData: []
        },
        onSubmit: value => {
            updateSupportOption.mutate(value);
        },
    })


    return (
        <div>
            <h1 className="text-[32px] prose text-gray font-semibold">Support System</h1>
            <div className="mt-8 lg:w-[625px] xl:w-[750px]">
                <div className="mt-4 flex-wrap flex gap-6  items-start">
                    <div className="flex flex-col gap-6">
                        <InputTextField
                            error={Boolean(formik.errors.ticketName)}
                            id="ticket-name" label={"Ticket name"} rows={1}
                            value={formik.values.ticketName}
                            onChange={(e) => formik.setFieldValue("ticketName", e.target.value)}/>

                        <SingleSelectField error={Boolean(formik.errors.channel)} text={"Hub channel"}
                                           value={formik.values.channel}
                                           optionValues={props.currentServer.textChannels}
                                           onChange={(_event, value) => formik.setFieldValue("channel", value?.id)}/>
                    </div>


                    <InputTextField
                        error={Boolean(formik.errors.message)}
                        id="message" label={"Message"} rows={5}
                        value={formik.values.message}
                        helperText={"You can use a custom embed with EmbedManager!"}
                        onChange={(e) => formik.setFieldValue("message", e.target.value)}/>
                </div>

                <button onClick={() => formik.handleSubmit()}
                        className={`${(formik.isValid && formik.dirty) ? "bg-light-blue hover:bg-white hover:text-light-blue" : ""} prose prose-base border-2 text-white rounded py-1 px-6 ease-in-out duration-150`}
                        id="action-btn">Resend
                </button>

            </div>
            <div className="mt-4">
                <SupportOption data={props.data} currentServer={props.currentServer}/>
            </div>
        </div>
    )
}

export default Support
