import * as React from "react";
import {useOutletContext, useParams} from "react-router-dom";
import {LinearProgress} from "@mui/material";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
    deleteTempChannel,
    fetchTempChannelHubs,
    insertTempChannel
} from "../../api/QueryService";
import {Server} from "../../types/Server";
import {useState} from "react";
import {useFormik} from "formik";
import {AiOutlinePlus} from "react-icons/ai";
import {InputTextField} from "../../components/form-components/InputTextField";
import {SingleSelectField} from "../../components/form-components/SingleSelectField";
import {TempChannelType} from "../../types/TempChannelType";
import {FormSwitch} from "../../components/form-components/FormSwitch";
import TempChannelListDisplay from "../../components/TempChannelListDisplay";
import * as Yup from 'yup';
import {SubmitButtons} from "../../components/form-components/SubmitButtons";

function TempChannel() {

    const {serverId} = useParams()
    const servers: Server[] = useOutletContext();
    const matchedServer = servers.find(server => server.id === serverId);

    const {data, isLoading} = useQuery({
        queryKey: ["tempChannelHubs"],
        queryFn: () => fetchTempChannelHubs(serverId ?? "0")
    })

    if (data === undefined || isLoading || matchedServer === undefined) {
        return <LinearProgress/>
    }

    return <Child data={data} currentServer={matchedServer}/>
}

const Child = (props: { data: TempChannelType[], currentServer: Server }) => {

    const queryClient = useQueryClient();

    const [open, setOpen] = useState(false)
    const updateTempChannel = useMutation({
        mutationFn: (data: TempChannelType) => insertTempChannel(props.currentServer.id ?? "0", data),
        onSuccess: () => {
            queryClient.invalidateQueries(["tempChannelHubs"])
            formik.setValues(formik.initialValues)
        }
    })

    const removeTempChannel = useMutation({
        mutationFn: (value: string) => deleteTempChannel(props.currentServer.id ?? "0", value),
        onSuccess: () => {
            queryClient.invalidateQueries(["tempChannelHubs"])
            formik.setValues(formik.initialValues)
        }
    })


    const ValidationSchema = Yup.object().shape({
        privateCommandChannel: Yup.boolean()
            .required('Required'),
        name: Yup.string()
            .min(2, 'Too Short!')
            .max(16, 'Too Long!')
            .required('Required'),
        channel: Yup.string().min(10, 'Invalid id').required('Required'),
    });

    const formik = useFormik({
        validationSchema: ValidationSchema,
        initialValues: {
            privateCommandChannel: true,
            name: '',
            channel: '',
        },
        onSubmit: values => {
            updateTempChannel.mutate(values)
        },
    })


    return (
        <div>
            <h1 className="text-[32px] prose text-gray font-semibold">TempChannel Hubs</h1>
            <div className="mt-4 lg:w-[625px] xl:w-[750px]">
                <div
                    className={`${open ? "border-b-cardGray" : ""} border-2 rounded border-light-blue bg-sidebar-black p-5 flex items-center justify-between gap-4`}>
                    <h2 className="prose prose-2xl text-white font-bold">Add a new temporary channel</h2>
                    <AiOutlinePlus className="text-white" onClick={() => setOpen(!open)}
                                   size={"2rem"}/>
                </div>
                <div>
                    {open &&
                        <div className="bg-sidebar-black p-5">
                            <div
                                className="flex flex-wrap justify-left gap-4 mb-5 mt-5">
                                <InputTextField
                                    error={Boolean(formik.errors.name)}
                                    id="name" label={"Name"} rows={1}
                                    value={formik.values.name}
                                    helperText={"%name% | %nickname% | %tag% | %couter%"}
                                    onChange={(e) => formik.setFieldValue("name", e.target.value)}/>
                                <SingleSelectField error={Boolean(formik.errors.channel)} text={"Voice channel"}
                                                   value={formik.values.channel}
                                                   optionValues={props.currentServer.voiceChannels}
                                                   onChange={(_event, value) => formik.setFieldValue("channel", value?.id)}/>
                            </div>
                            <div className="my-3.5">
                                <p className="prose-base prose text-gray">Should you be able to edit this channel via
                                    commands?</p>
                                <FormSwitch checked={formik.values.privateCommandChannel}
                                            onChange={(_event, value) => formik.setFieldValue("privateCommandChannel", value)}/>
                            </div>
                            <SubmitButtons valid={formik.isValid && formik.dirty} onSubmit={formik.submitForm}
                                           onReset={() => formik.setValues(formik.initialValues)}/>
                        </div>
                    }
                </div>
                <TempChannelListDisplay label={"Temporary Hubs"} data={props.data} currentServer={props.currentServer}
                                        onDelete={(value) => removeTempChannel.mutate(value)}
                                        onChange={(value: TempChannelType) => {
                                            formik.setValues(value)
                                            setOpen(true)
                                        }}/>
            </div>
        </div>
    )
}

export default TempChannel
