import React from 'react'
import {Autocomplete, TextField} from "@mui/material";


interface IOption {
    name: string
    id: string
}

export const CategorySelectField = (props: {
    error?: boolean
    text: string,
    value: string,
    optionValues: Array<IOption>,
    disableCloseOnSelect?: boolean,
    onChange: (event: any, value: IOption | null) => void
}) => {
    const [inputValue, setInputValue] = React.useState('');
    return (
        <div>
            <Autocomplete
                sx={{minWidth: "280px"}}
                onChange={props.onChange}
                value={props.optionValues.find((option) => option.id === props.value) ?? null}
                inputValue={inputValue}
                onInputChange={(_event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                options={props.optionValues}
                getOptionLabel={(option) => option.name}
                disableCloseOnSelect={props.disableCloseOnSelect}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField error={props.error} {...params} label={props.text}/>
                )}
            />
        </div>
    )
}
