import {Server} from "../types/Server";
import {BiEdit, BiTrash} from "react-icons/bi";
import * as React from "react";
import {TempChannelType} from "../types/TempChannelType";
import {SelectionRoleType} from "../types/SelectionRoleType";

function SelectionRoleListDisplay(props: {
    onDelete: (channel: string) => void,
    label: string,
    data: SelectionRoleType[],
    currentServer: Server,
}) {
    return (
        <div className="mt-4 bg-sidebar-black p-5 rounded">
            <div className="flex items-center justify-between gap-4">
                <h3 className="prose-xl prose font-semibold text-white opacity-50">{props.label}</h3>
                <span
                    className="prose-xl prose font-semibold text-white opacity-50">{props.data.length}/∞</span>
            </div>

            <hr className="mt-3.5 mb-6 border-t-2 border-white opacity-50"/>

            <div className="flex flex-col justify-center gap-4">
                {props.data.map((role, index) => {
                    return (
                        <div
                            key={"temp-channel" + index}
                            className="bg-cardGray rounded py-4 px-3 flex gap-4 justify-between items-center">
                            <div className="flex items-center gap-4">
                                <h4 className="text-white prose prose-xl">{role.name}</h4>
                                <p className="text-white opacity-50 prose-sm">
                                    {props.currentServer.roles.find((vc) => vc.id === role.roleId)?.name}
                                </p>
                            </div>
                            <div className="flex items-center gap-4">
                                <BiTrash className="cursor-pointer text-white" onClick={() => props.onDelete(role.roleId)} size={"1.5rem"}/>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default SelectionRoleListDisplay;
