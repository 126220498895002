import {useState} from "react";
import {AiOutlinePlus} from "react-icons/ai";
import * as React from "react";
import {InputTextField} from "../form-components/InputTextField";
import {EmbedManagerType} from "../../types/EmbedManagerType";
import {FormSwitch} from "../form-components/FormSwitch";

function EmbedManagerFooter(props: {
    type: EmbedManagerType,
    onTextChange: (value: string) => void,
    onIconChange: (value: string) => void,
    onTimeStampChange: (value: boolean) => void
}) {

    const [open, setOpen] = useState(false)

    return (
        <div className="border-b-2 border-t-1 border-grayNew py-4">
            <div className="flex justify-between items-center">
                <h4 className="prose-base prose text-white">Footer</h4>
                <AiOutlinePlus className="cursor-pointer text-white" size={20} onClick={() => setOpen(!open)}/>
            </div>

            {open &&
                <div className="mt-4 flex flex-col justify-center gap-4">
                    <InputTextField
                        id="footer.text" label={"Text"} rows={1}
                        value={props.type.embed.footer.text}
                        onChange={(e) => props.onTextChange(e.target.value)}/>

                    <InputTextField
                        id="footer.icon" label={"Icon"} rows={1}
                        value={props.type.embed.footer.icon}
                        onChange={(e) => props.onIconChange(e.target.value)}/>

                    <div>
                        <p>Use current timestamp?</p>
                        <FormSwitch checked={props.type.embed.timeStamp} onChange={(e, v) => props.onTimeStampChange(v)}/>
                    </div>
                </div>
            }

        </div>
    )
}

export default EmbedManagerFooter;
