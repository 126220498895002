import * as React from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {deleteTwitchNotification, fetchTwitchChannel, insertOrUpdateTwitchChannel} from "../../api/QueryService";
import {useOutletContext, useParams} from "react-router-dom";
import {LinearProgress} from "@mui/material";
import {Server} from "../../types/Server";
import {SocialType} from "../../types/SocialType";
import {AiOutlinePlus} from "react-icons/ai";
import SocialChannelDisplay from "../../components/SocialListDisplay";
import {useState} from "react";
import {InputTextField} from "../../components/form-components/InputTextField";
import {SingleSelectField} from "../../components/form-components/SingleSelectField";
import {TwitchChannelSearchField} from "../../components/form-components/TwitchChannelSearchField";
import {useFormik} from "formik";
import {SubmitButtons} from "../../components/form-components/SubmitButtons";
import * as Yup from "yup";

function Twitch() {

    const {serverId} = useParams()
    const servers: Server[] = useOutletContext();
    const matchedServer = servers.find(server => server.id === serverId);

    const {data, isLoading} = useQuery({
        queryKey: ['twitch-notification'],
        queryFn: () => fetchTwitchChannel(serverId ?? "0")
    })

    if (data === undefined || isLoading || matchedServer === undefined) {
        return <LinearProgress/>
    }

    return <Child data={data} currentServer={matchedServer}/>
}

const Child = (props: { data: SocialType[], currentServer: Server }) => {

    const [open, setOpen] = useState(false)
    const queryClient = useQueryClient()

    const removeNotification = useMutation({
        mutationFn: (data: SocialType) => deleteTwitchNotification(props.currentServer.id, data),
        onSuccess: () => queryClient.invalidateQueries({queryKey: ["twitch-notification"]})
    })

    const mutateTwitch = useMutation({
        mutationFn: (data: SocialType) => insertOrUpdateTwitchChannel(props.currentServer.id, data),
        onSuccess: () => queryClient.invalidateQueries({queryKey: ["twitch-notification"]})
    })

    const ValidationSchema = Yup.object().shape({
        socialChannel: Yup.object().shape({
            id: Yup.string().required('Required')
        }).required('Required'),
        message: Yup.string()
            .min(2, 'Too Short!')
            .max(300, 'Too Long!')
            .required('Required'),
        channel: Yup.string().min(10, 'Invalid id').required('Required'),
    });

    const formik = useFormik({
        validationSchema: ValidationSchema,
        initialValues: {
            socialChannel: {id: "", name: ""},
            message: '',
            channel: '',
        },
        onSubmit: value => {
            console.log(value)
            mutateTwitch.mutate(value)
        },
    })

    return (
        <div>
            <h1 className="text-[32px] prose text-gray font-semibold">Twitch</h1>
            <div className="mt-4 lg:w-[625px] xl:w-[750px]">
                <div
                    className={`${open ? "border-b-cardGray" : ""} border-2 rounded border-light-blue bg-sidebar-black p-5 flex items-center justify-between gap-4`}>
                    <h2 className="prose prose-2xl text-white font-bold">Add a new notification</h2>
                    <AiOutlinePlus className="text-white" onClick={() => setOpen(!open)} size={"2rem"}/>
                </div>
                <div>
                    {open &&
                        <div className="bg-sidebar-black p-5">
                            <form className="flex flex-wrap justify-left gap-4 mb-5 mt-5"
                                  onSubmit={formik.handleSubmit}>
                                <InputTextField id="message" label={"Notification message"} rows={3}
                                                value={formik.values.message} error={Boolean(formik.errors.message)}
                                                helperText={"%stream% (Title) | %name% (Streamer) | %game% | %link%"}
                                                onChange={(event) => formik.setFieldValue("message", event.target.value)}/>
                                <SingleSelectField error={Boolean(formik.errors.channel)} text={"Text channel"} value={formik.values.channel}
                                                   optionValues={props.currentServer.textChannels}
                                                   onChange={(_event, value) => formik.setFieldValue("channel", value?.id)}/>
                                <TwitchChannelSearchField text={"Select a twitch channel"}
                                                          error={Boolean(formik.errors.socialChannel?.id)}
                                                          value={formik.values.socialChannel}
                                                          onChange={(value) => formik.setFieldValue("socialChannel", value)}/>
                            </form>
                            <SubmitButtons valid={formik.isValid && formik.dirty} onSubmit={formik.submitForm}
                                           onReset={() => formik.setValues(formik.initialValues)}/>
                        </div>
                    }
                </div>
                <SocialChannelDisplay label={"Twitch"} data={props.data} currentServer={props.currentServer}
                                      onDelete={(value: SocialType) => removeNotification.mutate(value)}
                                      onChange={(value: SocialType) => {
                                          formik.setValues(value)
                                          setOpen(true)
                                      }}/>
            </div>
        </div>
    )
}
export default Twitch
