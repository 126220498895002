import React from 'react'
import {Link} from 'react-router-dom'
import {Skeleton} from "@mui/material";
import {useQuery} from "react-query";
import {fetchServers} from "../api/QueryService"
import {MdAdd} from "react-icons/md";


function Dashboard() {

    const {data, status} = useQuery('servers', fetchServers)

    return (
        <>
            <div className="my-[75px] container">
                <div className="my-[20px] flex items-center justify-between">
                    <h3 className="prose-2xl font-bold text-gray-200">
                        Select your Server
                    </h3>

                    <div className="bg-gray-700 rounded-xl hover:bg-gray-800">
                        <a rel="noreferrer" target="_blank"
                           href="https://discord.com/oauth2/authorize?client_id=723394954193272892&scope=applications.commands%20bot&permissions=8">
                            <MdAdd size="45px"/>
                        </a>
                    </div>
                </div>

                <hr className="text-gray-200"/>

                <div className="my-[30px] flex justify-start flex-wrap gap-7">

                    {status === 'loading' &&
                        <>
                            {[...Array(20),].map(() => (
                                <Skeleton sx={{borderRadius: "10px"}} animation="wave" variant="rectangular" width={100}
                                          height={100}/>
                            ))}
                        </>
                    }

                    {status === 'error' &&
                        <>
                            <h1 className='animate-bounce my-3.5'>Ups, something went wrong. Please try again
                                later!</h1>
                        </>
                    }

                    {status === 'success' && data.map((_object, _name) => {
                        return (
                            <Link className="h-[100px] w-[100px] hover:my-2 hover:ease-in-out duration-300"
                                  to={_object.id}>
                                <img className="rounded w-[100px] h-[100px] object-cover"
                                     src={`https://cdn.discordapp.com/icons/${_object.id}/${_object.icon}`}
                                     alt={`${_object.name}-server-pic`}/>
                            </Link>
                        )
                    })}
                </div>
            </div>

        </>
    )
}

export default Dashboard;
