import * as React from 'react';
import {AiFillClockCircle,} from "react-icons/ai";
import {SiDiscord} from "react-icons/si"
import {BsServer, BsPencilFill, BsFillShieldLockFill} from "react-icons/bs"
import {TbFreeRights} from "react-icons/tb"
import {BiSupport} from "react-icons/bi"
import {FaRocket} from "react-icons/fa"
import {useQuery} from "react-query";
import {fetchServerCount, me} from "../api/QueryService";

function Home() {

    const aboutCards = [
        {
            Icon: <BsServer className="icon"/>,
            Title: "Powerful engine",
            Text: "With Interception's fast & highly powerful multithreading engine, which can process data and carry out actions quickly and securely, Interception remains fast and reliable"
        },
        {
            Icon: <AiFillClockCircle className="icon"/>,
            Title: "99.9% Uptime",
            Text: "We try to ensure up to 99.9% online time. Every update will be checked for bugs and will run through multiple tests. That’s how we avoid bugs and downtime."
        },
        {
            Icon: <BsPencilFill className="icon"/>,
            Title: "Customizable",
            Text: "Nothing is impossible. We try to improve with every single update and provide more and more features and settings to keep the user experience the best."
        },
        {
            Icon: <BiSupport className="icon"/>,
            Title: "Continuous SupportType",
            Text: "We also offer you 24/7 support. You can report a problem, request a data wipe, or even request a new feature that is not yet implemented."
        },
        {
            Icon: <BsFillShieldLockFill className="icon"/>,
            Title: "Secured",
            Text: "Every data request made by Interception is encrypted at an highly industrial level. Interception will not pass data on to third parties and remains closed."
        },
        {
            Icon: <FaRocket className="icon"/>,
            Title: "Easy to setup",
            Text: "We are trying to keep the user experience and design as simple as possible so that everyone is able to understand and use everything."
        },
    ]
    const {status} = useQuery("me", me)

    const {data, isLoading} = useQuery({
        queryFn: () => fetchServerCount()
    })

    return (
        <>

            <div className="mt-[35px] md:mt-[90px] xl:mt-[120px] py-6 container relative ">

                <img className="absolute z-[-1] right-0 top-0 object-cover object-center opacity-40 xl:opacity-100"
                     alt="home"
                     src="/start.svg"/>

                <div>
                    <h1 className={'font-heading font-bold text-white-text dark:text-gray-dark text-[30px] leading-[40px] md:text-[80px] md:leading-[80px] lg:text-[6rem] lg:leading-[100px] xl:text-[7rem] xl:leading-[115px]'}>
                        One Discord Bot. <br/>
                        Unlimited <br/>
                        opportunities
                    </h1>

                    <p className={'my-[50px] font-serif prose-base text-white-text dark:text-gray-dark leading-5 md:w-[425px]'}>
                        One Discord bot with many features to organize
                        and manage your server. Easy to set up and be
                        controlled by it’s own web interface.</p>
                </div>


                <div
                    className="flex items-center font-serif justify-between flex-col gap-5 md:items-start md:flex-row md:justify-start">
                    <a href="https://discord.com/oauth2/authorize?client_id=723394954193272892&scope=applications.commands%20bot&permissions=8"
                       className="btn-primary px-[15px] md:w-[250px] md:prose-xl">
                        <SiDiscord className="icon"/>
                        Add to Discord
                    </a>

                    {status === 'loading' || status === 'error' ?
                        <a href="/oauth2/authorization/discord" className="special-button">Log in</a> :
                        <a href="/dashboard" className="special-button">Dashboard</a>}
                </div>
            </div>

            <div className="bg-card-pink bg-no-repeat bg-right-bottom bg-40% py-6 lg:py-[233px]">
                <div
                    className="mt-12 md:mt-[150px] flex flex-wrap justify-center xl:justify-between border-collapse gap-8 md:gap-24 xl:gap-6 items-center container">

                    <div
                        className="w-[250px] h-[350px] md:w-[320px] md:h-[420px] rounded-[10px] bg-[#323232] flex flex-col justify-center items-center text-center p-5">
                        <h3 className="prose-2xl md:text-[35px] leading-normal font-bold mb-5 text-light-blue">Server <br/>
                            Moderation
                        </h3>
                        <p className="prose-base font-medium text-left w-[200px] leading-normal">
                            Add a support ticket system to your server and stay connected. Keep your sever safe with
                            Verification and greet or add a role to every new user like a king with our Join Manager.
                        </p>
                    </div>

                    <div
                        className="w-[250px] h-[350px] md:w-[320px] md:h-[420px] rounded-[10px] bg-[#323232] flex flex-col justify-center items-center text-center p-5">
                        <h3 className="prose-2xl md:text-[35px] leading-normal font-bold mb-5 text-light-blue">Live Social <br/>
                            Notifications
                        </h3>
                        <p className="prose-base font-medium text-left w-[200px] leading-normal">
                            Always stay up to date with your favorite YouTuber or Twitch streamer. With Interception you
                            have the possibility to be notified when something new happens
                        </p>
                    </div>

                    <div
                        className="w-[250px] h-[350px] md:w-[320px] md:h-[420px] rounded-[10px] bg-[#323232] flex flex-col justify-center items-center text-center p-5">
                        <h3 className="prose-2xl md:text-[35px] leading-normal font-bold mb-5 text-light-blue">Customizable <br/>
                            Music
                        </h3>
                        <p className="prose-base font-medium text-left w-[200px] leading-normal">
                            Interception does not only support Apple Music, Spotify or YouTube links. It also comes
                            with multiple filters and commands to customize your favourite music
                        </p>
                    </div>

                    <div
                        className="w-[250px] h-[350px] md:w-[320px] md:h-[420px] rounded-[10px] bg-[#323232] flex flex-col justify-center items-center text-center p-5">
                        <h3 className="prose-2xl md:text-[35px] leading-normal font-bold mb-5 text-light-blue">Temporary <br/>
                            Channel
                        </h3>
                        <p className="prose-base font-medium text-left w-[200px] leading-normal">
                            Keep your server clean by using temporary voice channels. After a voice channel is no longer
                            used, it is deleted and can be reopened at any time to cleanup your server.
                        </p>
                    </div>
                </div>
            </div>

            <div className="bg-pink w-full">
                <div className="flex justify-between items-center py-[20px] container">
                    <h1 className="prose-2xl leading-6 font-bold dark:text-gray">
                        Used by {data === undefined ? "300+" : data}<br/>
                        servers.
                    </h1>

                    <div className="grid grid-cols-3 gap-1">
                        <img
                            className='h-[40px] object-cover rounded-xl'
                            src="https://cdn.discordapp.com/icons/955188638558330971/8de25589a96417bfbb1f6aa1b3475323"
                            alt="food"/>
                        <img
                            className='h-[40px] object-cover rounded-xl'
                            src="https://cdn.discordapp.com/icons/374907103539625986/a_2f15bc6123d09395f962235bf28afc47"
                            alt="alphaexe"/>
                        <img
                            className='h-[40px] object-cover rounded-xl'
                            src="https://cdn.discordapp.com/icons/833198524123447316/a6c77e9b360807c9b1155e2491034c66.png?size=240"
                            alt="jaggs"/>
                        <img
                            className='h-[40px] object-cover rounded-xl'
                            src="https://cdn.discordapp.com/icons/374907103539625986/a_2f15bc6123d09395f962235bf28afc47"
                            alt="alphaexe"/>
                        <img
                            className='h-[40px] object-cover rounded-xl'
                            src="https://cdn.discordapp.com/icons/955188638558330971/8de25589a96417bfbb1f6aa1b3475323"
                            alt="food"/>
                        <img
                            className='h-[40px] object-cover rounded-xl'
                            src="https://cdn.discordapp.com/icons/833198524123447316/a6c77e9b360807c9b1155e2491034c66.png?size=240"
                            alt="jaggs"/>
                    </div>
                </div>
            </div>

            <div className="my-[100px] xl:py-10 container">
                <div className="flex flex-wrap lg:flex-nowrap justify-center md:justify-between gap-4">
                    <div className="text-white-text sm:mb-0 dark:text-gray-dark">
                        <h1 className={"prose-2xl text-center md:text-left text-light-blue font-bold mb-2"}>Why Interception?</h1>
                        <p className={"prose-base leading-5 w-[250px]"}>A multifunctional bot with many features.
                            Free of charge without further costs and
                            customizable</p>
                    </div>

                    <div
                        className="mt-4 lg:mt-0 flex justify-center md:justify-end gap-9 font-bold flex-wrap text-white-text">

                        {aboutCards.map((__object, __index) => {
                            return (
                                <div className="card max-w-[325px] my-5 text-white-text dark:text-gray-dark">
                                    <div className="flex flex-col items-center md:block">
                                        {__object.Icon}
                                        <h1 className={'prose-xl text-light-blue my-2'}>{__object.Title}</h1>
                                    </div>

                                    <p className={'text-justify md:text-left prose-base leading-5'}>{__object.Text}</p>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>

            <div className="py-4 text-white-text dark:text-gray-dark bg-dark-blue">
                <div className="flex justify-center flex-wrap md:justify-between flex-col md:flex-row items-center gap-4 container">
                    <div>
                        <h1 className="text-center prose-xl leading-5 md:text-left md:prose-2xl md:leading-7 font-bold">
                            Interception is and will <br/>
                            always stay
                            completely free
                        </h1>
                    </div>

                    <div>
                        <TbFreeRights className="h-20 w-20 xl:h-28 xl:w-28"/>
                    </div>
                </div>
            </div>

            <div className="h-[600px] md:h-[700px] grid place-content-center bg-ready2use bg-no-repeat bg-center">
                <h2 className="prose-xl dark:text-gray font-medium md:prose-2xl mb-10">Are you ready to <span className="font-bold text-pink">ENHANCE</span> <br/>
                    your <span className="font-bold text-light-blue">SERVER</span>?</h2>

                <div className="flex flex-col gap-4 justify-center items-center">
                    <a href="https://discord.com/oauth2/authorize?client_id=723394954193272892&scope=applications.commands%20bot&permissions=8"
                       className="btn-primary min-w-[200px] md:prose-xl">
                        <SiDiscord className="icon"/>
                        Lets go!!!
                    </a>

                    {status === 'loading' || status === 'error' ?
                        <a href="/oauth2/authorization/discord" className="special-button w-[100px]">Log in</a> :
                        <a href="/dashboard" className="special-button w-[100px]">Dashboard</a>}
                </div>

            </div>
        </>
    )
}

export default Home;
