import {Link} from 'react-router-dom'
import {useState} from "react";
import {useQuery} from "react-query";
import {me} from "../api/QueryService"
import {Avatar, IconButton, Menu, MenuItem} from "@mui/material";
import Hamburger from 'hamburger-react'


function Header() {
    const {data, status} = useQuery("me", me)

    const NAVIGATION = [
        {
            name: 'Donate',
            href: 'https://ko-fi.com/marvn',
            button: false
        },
        {
            name: 'Support',
            href: 'https://discord.com/invite/SSwXXZz',
            button: false
        },
        {
            name: 'Get Started',
            href: '/oauth2/authorization/discord',
            button: true
        },
    ]

    const [open, setOpen] = useState(false)
    const [openLogin, setOpenLogin] = useState(false)
    const openMenu = () => {
        setOpen(!open)
    }

    return (
        <>
            <header className="w-full bg-white dark:bg-dark-black">
                <div className={'py-3 container flex justify-between items-center'}>
                    <Link to='/'>
                        <img className={'h-[40px]'} src="/logo.svg" alt="interception-logo"/>
                    </Link>
                    <nav className={'hidden invisible md:visible md:block'}>
                        <ul className={'flex gap-[60px] items-center justify-center'}>
                            {NAVIGATION.map((_object, _index) => {
                                return (
                                    <li className={'text-white-text dark:text-light-blue prose-base'} key={_object.name}>
                                        {_object.button ?
                                            <>
                                                {status === 'loading' || status === 'error' ?
                                                    <a href={_object.href} className="btn-primary-small">{_object.name}</a>
                                                    :
                                                    <div>
                                                        <IconButton onClick={() => setOpenLogin(true)}>
                                                            <Avatar src={data?.avatar}/>
                                                        </IconButton>
                                                        <Menu anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                                                              open={openLogin} onClose={() => setOpenLogin(false)}>
                                                            <MenuItem
                                                                onClick={() => window.location.href = "/dashboard"}>Dashboard</MenuItem>
                                                            <MenuItem
                                                                onClick={() => window.location.href = "/logout"}>Logout</MenuItem>
                                                        </Menu>
                                                    </div>

                                                    //<a href="/logout" className="header-button">Logout</a>
                                                }
                                            </>
                                            :
                                            <a href={_object.href}>{_object.name}</a>}
                                    </li>
                                )
                            })}
                        </ul>
                    </nav>

                    <div className="visible md:invisible md:hidden top-2 right-3">
                        <Hamburger color="#ffffff" size={40} onToggle={openMenu} toggled={open}/>
                    </div>

                </div>
            </header>

            {open &&
                <div
                    className="container bg-gray-dark dark:bg-[#1C1C1C] dark:bg-opacity-30 flex flex-col justify-center py-[30px] gap-[10px] transition duration-500">
                    {NAVIGATION.map((_object, _index) => {
                        return (
                            <li className={'list-none text-white-text dark:text-gray-dark prose-base font-bold font-heading'}
                                key={_object.name}>
                                {_object.button ?
                                    <>
                                        {status === 'loading' || status === 'error' ?
                                            <a href={_object.href}
                                               className="text-dark-blue dark:text-light-blue">{_object.name}</a>
                                            :
                                            <a href="/logout" className="text-dark-blue dark:text-light-blue">Logout</a>
                                        }
                                    </>
                                    :
                                    <a href={_object.href}>{_object.name}</a>}
                            </li>
                        )
                    })}
                </div>
            }
        </>

    )
}

export default Header;
