import {MdSpaceDashboard} from "react-icons/md";
import {AiOutlineUserAdd, AiFillYoutube} from "react-icons/ai"
import {GoGraph} from "react-icons/go";
import {ImEmbed} from "react-icons/im";
import {BsMicFill, BsTwitch, BsEmojiSmile} from "react-icons/bs"
import {NavLink} from "react-router-dom";
import {TiTicket} from "react-icons/ti";
import {icon} from "picmo/dist/icons";

function SidebarOptions() {

    const options = [
        {
            icon: <MdSpaceDashboard className="w-7 h-7"/>,
            name: 'Home',
            href: './',
            deactivated: false
        },
        {
            icon: <AiOutlineUserAdd className="w-7 h-7"/>,
            name: 'JoinManager',
            href: 'join-manager',
            deactivated: false
        },
        {
            icon: <ImEmbed className="w-7 h-7"/>,
            name: 'Embed Manager',
            href: 'embed-manager',
            deactivated: false
        },
        {
            icon: <TiTicket className="w-7 h-7"/>,
            name: 'Support System',
            href: 'support',
            deactivated: false
        },
    ]

    const SOCIAL = [
        {
            icon: <AiFillYoutube className="w-6 h-6"/>,
            name: 'YouTube',
            href: 'youtube',
            deactivated: false
        },
        {
            icon: <BsTwitch className="w-6 h-6"/>,
            name: 'Twitch',
            href: 'twitch',
            deactivated: false
        },
    ]

    const MISCELLANEOUS = [
        {
            icon: <BsMicFill className="w-6 h-6"/>,
            name: 'TempChannel',
            href: 'temp-channel',
            deactivated: false
        },
        {
            icon: <GoGraph className="w-6 h-6"/>,
            name: 'Selection Roles',
            href: 'selection-roles',
            deactivated: false
        },
        // {
        //     icon: <BsEmojiSmile className="w-6 h-6"/>,
        //     name: 'Custom Emojis',
        //     href: 'custom-emojis',
        //     deactivated: true
        // }
    ]

    return (
        <div className="w-full overflow-y-scroll">

            <div className="mb-4">
                <div>
                    <span className="text-gray-500 prose-sm font-semibold prose">General</span>
                </div>
                {options.map((_object, _index) => {
                    return (
                        <NavLink
                            className={
                                ({isActive}) => isActive ? 'pl-2 sidebar-option--active text-white bg-gray-700' : 'pl-2 sidebar-option hover:text-gray-300'
                            }
                            to={_object.href} key={_object.name}>
                            {_object.icon}
                            <span className="ml-2 prose-base">{_object.name}</span>
                        </NavLink>
                    )
                })}
            </div>

            <div className="mb-4">
                <span className="text-gray-500 prose-sm font-semibold prose">Social</span>
                {SOCIAL.map((_object, _index) => {
                    return (
                        <NavLink
                            className={
                                ({isActive}) => isActive ? 'pl-2 sidebar-option--active text-gray-200 bg-gray-700' : 'pl-2 sidebar-option hover:bg-gray-700 hover:text-gray-300'
                            }
                            to={_object.href} key={_object.name}>
                            {_object.icon}
                            <span className="ml-2 prose-base">{_object.name}</span>
                        </NavLink>
                    )
                })}
            </div>

            <div className="mb-4">
                <span className="text-gray-500 prose-sm font-semibold prose">Miscellaneous</span>
                {MISCELLANEOUS.map((_object, _index) => {
                    return (
                        <NavLink
                            className={
                                ({isActive}) => isActive ? 'pl-2 sidebar-option--active text-gray-200 bg-gray-700' : 'pl-2 sidebar-option hover:bg-gray-700 hover:text-gray-300'
                            }
                            to={_object.href} key={_object.name}>
                            {_object.icon}
                            <span className="ml-2 prose-base">{_object.name}</span>
                        </NavLink>
                    )
                })}
            </div>
        </div>
    )
}

export default SidebarOptions
