import ky from "ky"
import {Server} from "../types/Server"
import {User} from "../types/User";
import {JoinManagerType} from "../types/JoinManagerType";
import {TempChannelType} from "../types/TempChannelType";
import Cookies from 'js-cookie';
import {GeneralType} from "../types/GeneralType";
import {SupportType} from "../types/SupportType";
import {SocialType} from "../types/SocialType";
import {SocialChannelType} from "../types/SocialChannelType";
import {EmbedManagerType} from "../types/EmbedManagerType";
import {SelectionRoleType} from "../types/SelectionRoleType";
import {EmojiGG} from "../types/EmojiGG";
import {CustomEmoji} from "../types/CustomEmoji";

const ORIGIN = "/api/discord"
const SOCIAL = "/api/social"

const api = ky.extend({
    hooks: {
        beforeRequest: [request => {
            request.headers.set('X-XSRF-TOKEN', Cookies.get('XSRF-TOKEN') ?? "0")
        }],

        afterResponse: [
            async (_request, _options, response) => {
                console.log(response.status)
                if (response.status === 403 || response.status === 401 || response.status === 400 || response.status === 404 || response.status === 500) {
                    console.log("An error occurred!!! Status: " + response.status);
                }
            }
        ]
    }
})

export async function fetchServerCount() {
    return await api.get(`${ORIGIN}/server-count`).json<String>()
}

export async function fetchServers() {
    return await api.get(`${ORIGIN}/guilds`).json<Server[]>()
}

export async function me() {
    return await api.get(`${ORIGIN}/@me`).json<User>()
}

export async function fetchGeneral(serverId: string) {
    return await api.get(`${ORIGIN}/general`, {
        headers: {
            'X-Server': serverId
        }
    }).json<GeneralType>()
}

export async function insertGeneral(serverId: string, body: any) {
    return api.post(`${ORIGIN}/general`, {
        headers: {
            'X-Server': serverId
        },
        json: body
    })
}

export async function fetchJoinManager(serverId: string) {
    return await api.get(`${ORIGIN}/join-manager`, {
        headers: {
            'X-Server': serverId
        }
    }).json<JoinManagerType>()
}

export async function insertJoinManager(serverId: string, body: JoinManagerType) {
    return api.post(`${ORIGIN}/join-manager`, {
        headers: {
            'X-Server': serverId
        },
        json: body
    })
}

export async function fetchTempChannelHubs(serverId: string) {
    return await api.get(`${ORIGIN}/temp-channel`, {
        headers: {
            'X-Server': serverId
        }
    }).json<TempChannelType[]>()
}

export async function insertTempChannel(serverId: string, body: any) {
    return api.post(`${ORIGIN}/temp-channel/`, {
        headers: {
            'X-Server': serverId
        },
        json: body
    })
}

export async function deleteTempChannel(serverId: string, channelId: string) {
    return api.delete(`${ORIGIN}/temp-channel/`, {
        headers: {
            'X-Server': serverId,
            'X-Voice-Channel': channelId
        }
    })
}

export async function fetchTwitchChannel(serverId: string) {
    return await api.get(`${ORIGIN}/notifications/twitch`, {
        headers: {
            'X-Server': serverId
        },
    }).json<SocialType[]>()
}

export async function fetchYTChannel(serverId: string) {
    return await api.get(`${ORIGIN}/notifications/yt`, {
        headers: {
            'X-Server': serverId
        },
    }).json<SocialType[]>()
}

export async function fetchSupport(serverId: string) {
    return await api.get(`${ORIGIN}/support`, {
        headers: {
            'X-Server': serverId
        }
    }).json<SupportType>()
}

export async function insertSupportData(serverId: string, body: any) {
    return api.post(`${ORIGIN}/support`, {
        headers: {
            'X-Server': serverId
        },
        json: body
    })
}

export async function insertSupportOption(serverId: string, body: any) {
    return api.post(`${ORIGIN}/support/options/`, {
        headers: {
            'X-Server': serverId
        },
        json: body
    })
}

export async function deleteSupportOption(serverId: string, option: string) {
    return api.delete(`${ORIGIN}/support/options/`, {
        headers: {
            'X-Server': serverId,
            'X-Support-Option': option,
        }
    })
}

export async function fetchTwitchChannelByName(input: string) {
    return await api.get(`${SOCIAL}/twitch/find`, {
        headers: {
            'X-Channel': input
        }
    }).json<SocialChannelType[]>()
}

export async function fetchYouTubeChannelByName(input: string) {
    return await api.get(`${SOCIAL}/youtube/find`, {
        headers: {
            'X-Channel': input
        }
    }).json<SocialChannelType[]>()
}

export async function fetchEmbedsFromServer(server: string) {
    return await api.get(`${ORIGIN}/embed-manager/`, {
        headers: {
            'X-Server': server
        }
    }).json<EmbedManagerType[]>()
}

export async function insertOrUpdateEmbed(serverId: string, body: EmbedManagerType) {
    return api.post(`${ORIGIN}/embed-manager/`, {
        headers: {
            'X-Server': serverId
        },
        json: body
    })
}

export async function deleteEmbed(serverId: string, uuid: string) {
    return api.delete(`${ORIGIN}/embed-manager/`, {
        headers: {
            'X-Server': serverId,
            'X-UUID': uuid
        }
    })
}

export async function insertOrUpdateTwitchChannel(serverId: string, body: SocialType) {
    return api.post(`${ORIGIN}/notifications/twitch`, {
        headers: {
            'X-Server': serverId
        },
        json: body
    })
}

export async function deleteTwitchNotification(serverId: string, social: SocialType) {
    return api.delete(`${ORIGIN}/notifications/twitch`, {
        json: social,
        headers: {
            'X-Server': serverId
        }
    })
}

export async function insertOrUpdateYouTubeChannel(serverId: string, body: SocialType) {
    return api.post(`${ORIGIN}/notifications/yt`, {
        headers: {
            'X-Server': serverId
        },
        json: body
    })
}

export async function deleteYouTubeNotification(serverId: string, social: SocialType) {
    return api.delete(`${ORIGIN}/notifications/yt`, {
        json: social,
        headers: {
            'X-Server': serverId
        }
    })
}

export async function fetchSelectionRoles(server: string) {
    return await api.get(`${ORIGIN}/roles-selection/`, {
        headers: {
            'X-Server': server
        }
    }).json<SelectionRoleType[]>()
}

export async function insertOrUpdateSelectionRole(serverId: string, body: SelectionRoleType) {
    return api.post(`${ORIGIN}/roles-selection/`, {
        headers: {
            'X-Server': serverId
        },
        json: body
    })
}

export async function deleteSelectionRole(serverId: string, roleId: string) {
    return api.delete(`${ORIGIN}/roles-selection/`, {
        headers: {
            'X-Server': serverId,
            'X-ROLE': roleId
        }
    })
}

export async function fetchCustomEmojis(server: string) {
    return await ky.get(`https://emoji.gg/api/`, {
        // headers: {
        //     'X-Server': server
        // }
    }).json<EmojiGG[]>()
}

export async function addCustomEmojiToServer(serverId: string, body: CustomEmoji[]) {
    return api.post(`${ORIGIN}/custom-emojis`, {
        headers: {
            'X-Server': serverId
        },
        json: body
    })
}
