import React from 'react'
import {Switch} from "@mui/material";

export const FormSwitch = (props: { checked: boolean, onChange: (event: any, value: boolean) => void }) => {

    return (
        <Switch
            onChange={props.onChange}
            checked={props.checked}
        />
    )
}
