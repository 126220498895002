import React, {useState} from "react";
import {Server} from "../../types/Server";
import {AiOutlinePlus, AiOutlineMinus} from "react-icons/ai";


function SidebarProfile(props: { avatar: string, name: string, server: string, serverId: string, servers: Server[] }) {

    const [open, setOpen] = useState(false);
    const openMenu = () => {
        setOpen(!open)
    };

    return (
        <div className="w-full">
            <div className="flex items-center justify-between hover:cursor-pointer" onClick={openMenu}>
                <div className="flex items-center overflow-hidden">
                    <img className="w-10 h-10 rounded-[5px]" src={props.avatar} alt={props.name}/>
                    <span className="ml-3 prose-base font-bold max-w-[250px] text-gray">{props.server}</span>
                </div>
                <div>
                    {open ? <AiOutlineMinus/> : <AiOutlinePlus/>}
                </div>
            </div>


            {open &&
                <div
                    className="absolute z-[999999] flex flex-col justify-center gap-2 rounded w-[276px] bg-grayNew max-h-[500px] p-1 mt-4 overflow-x-hidden overflow-y-scroll">
                    {props.servers.map((_object, _index) => {
                        if (_object.id === props.serverId) {
                            return (<></>)
                        }

                        return (
                            <a href={`/dashboard/${_object.id}`}
                               className="bg-gray-800 rounded flex items-center hover:text-light-blue">
                                <img className="rounded w-10 h-10 object-cover"
                                     src={`https://cdn.discordapp.com/icons/${_object.id}/${_object.icon}`}
                                     alt={`${_object.name}-server-pic`}/>
                                <span
                                    className="ml-3 prose-base font-bold max-w-[250px] text-gray">{_object.name}</span>
                            </a>
                        )
                    })}
                </div>
            }

        </div>
    )
}

export default SidebarProfile
