import * as React from "react";
import {useState} from "react";
import {useMutation, useQueryClient} from "react-query";
import {deleteSupportOption, insertSupportOption} from "../api/QueryService";
import {Server} from "../types/Server";
import {useFormik} from "formik";
import {AiOutlinePlus} from "react-icons/ai";
import {InputTextField} from "./form-components/InputTextField";
import {SingleSelectField} from "./form-components/SingleSelectField";
import * as Yup from 'yup';
import {SubmitButtons} from "./form-components/SubmitButtons";
import {SupportType} from "../types/SupportType";
import {MultiSelectField} from "./form-components/MultiSelectFIeld";
import {CategorySelectField} from "./form-components/CategorySelectField";
import SupportOptionListDisplay from "./SupportOptionListDisplay";
import EmojiPicker, {EmojiStyle, Theme} from "emoji-picker-react";

function SupportOption(props: { data: SupportType, currentServer: Server }) {
    const queryClient = useQueryClient();

    const [open, setOpen] = useState(false)
    const [openEmoji, setOpenEmoji] = useState(false)

    const updateSupportOption = useMutation({
        mutationFn: (data: any) => insertSupportOption(props.currentServer.id ?? "0", data),
        onSuccess: () => {
            queryClient.invalidateQueries(["fetchSupport"])
            formik.setValues(formik.initialValues)
        }
    })

    const removeSupportOption = useMutation({
        mutationFn: (data: string) => deleteSupportOption(props.currentServer.id ?? "0", data),
        onSuccess: () => {
            queryClient.invalidateQueries(["fetchSupport"])
            formik.setValues(formik.initialValues)
        }
    })

    const ValidationSchema = Yup.object().shape({
        name: Yup.string().required(),
        description: Yup.string().required(),
        category: Yup.string()
            .required('Required'),
        message: Yup.string()
            .min(2, 'Too Short!')
            .required('Required'),
        logsChannel: Yup.string().required('Required'),
        roles: Yup.array().required('Required'),
    });
    const formik = useFormik({
        validationSchema: ValidationSchema,
        initialValues: {
            name: '',
            description: '',
            emoji: '',
            category: '',
            message: '',
            logsChannel: '',
            roles: []
        },
        onSubmit: value => {
            updateSupportOption.mutate(value)
            setOpen(false)
        },
    })

    return (
        <div>
            <h1 className="text-[32px] prose text-gray font-semibold">Support options</h1>
            <div className="mt-4">
                <div
                    className={`${open ? "border-b-cardGray" : ""} border-2 rounded border-light-blue bg-sidebar-black p-5 flex items-center justify-between gap-4`}>
                    <h2 className="prose prose-2xl text-white font-bold">Manage your options</h2>
                    <AiOutlinePlus className="text-white" onClick={() => setOpen(!open)}
                                   size={"2rem"}/>
                </div>
                <div>
                    {open &&
                        <div className="bg-sidebar-black p-5">
                            <div
                                className="flex flex-wrap justify-start gap-4 mb-5 mt-5">
                                <div className="flex flex-col gap-6 flex-wrap">
                                    <InputTextField
                                        error={Boolean(formik.errors.name)}
                                        id="name" label={"Name"} rows={1}
                                        value={formik.values.name}
                                        onChange={(e) => formik.setFieldValue("name", e.target.value)}/>

                                    <InputTextField
                                        error={Boolean(formik.errors.description)}
                                        id="description" label={"Description"} rows={1}
                                        value={formik.values.description}
                                        onChange={(e) => formik.setFieldValue("description", e.target.value)}/>

                                    <div
                                        className="border-2 rounded w-[50px] grid place-content-center h-[50px] border-grayNew"
                                        onClick={() => setOpenEmoji(!openEmoji)}>
                                        <span className="text-2xl">{formik.values.emoji}</span>
                                    </div>

                                    {openEmoji && <div className="">
                                        <EmojiPicker
                                            theme={Theme.DARK}
                                            width="280px"
                                            emojiStyle={EmojiStyle.TWITTER}
                                            onEmojiClick={(emoji) => {
                                                formik.setFieldValue("emoji", emoji.emoji)
                                            }}/>
                                    </div>}

                                </div>

                                <InputTextField
                                    error={Boolean(formik.errors.message)}
                                    id="message" label={"Message"} rows={8}
                                    fullWidth
                                    value={formik.values.message}
                                    onChange={(e) => formik.setFieldValue("message", e.target.value)}/>

                                <div className="flex flex-col gap-6 flex-wrap">
                                    <MultiSelectField text={"Roles"}
                                                      value={formik.values.roles}
                                                      optionValues={props.currentServer.roles}
                                                      onChange={(_event, value) => {
                                                          formik.setFieldValue("roles", value.map(v => v.id))
                                                      }}/>

                                    <CategorySelectField error={Boolean(formik.errors.category)}
                                                         text={"Ticket category"}
                                                         value={formik.values.category}
                                                         optionValues={props.currentServer.categories}
                                                         onChange={(_event, value) => formik.setFieldValue("category", value?.id)}/>

                                    <SingleSelectField error={Boolean(formik.errors.logsChannel)} text={"Logs channel"}
                                                       value={formik.values.logsChannel}
                                                       optionValues={props.currentServer.textChannels}
                                                       onChange={(_event, value) => formik.setFieldValue("logsChannel", value?.id)}/>
                                </div>
                            </div>
                            <SubmitButtons valid={formik.isValid && formik.dirty} onSubmit={formik.submitForm}
                                           onReset={() => formik.setValues(formik.initialValues)}/>
                        </div>
                    }
                </div>
                <SupportOptionListDisplay label={"Options"} data={props.data} currentServer={props.currentServer}
                                          onDelete={(value) => removeSupportOption.mutate(value)}
                                          onChange={(value: any) => {
                                              formik.setValues(value)
                                              setOpen(true)
                                          }}/>
            </div>
        </div>
    )
}

export default SupportOption
