import React from 'react'
import {TextField} from "@mui/material";

export const InputTextField = (props: {
    id?: string
    label: string
    rows: number
    fullWidth?: boolean,
    error?: boolean,
    helperText?: any,
    value: string,
    onChange: (e: any) => void
}) => {
    return (
        <div>
            <TextField
                fullWidth={props.fullWidth}
                id={props.id}
                name={props.id}
                error={props.error}
                helperText={props.helperText}
                sx={{minWidth: "280px"}}
                onChange={props.onChange}
                value={props.value}
                label={props.label}
                multiline
                rows={props.rows}
                variant="outlined"
            />
        </div>
    )
}
