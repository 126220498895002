import {
    DiscordEmbed,
    DiscordEmbedDescription, DiscordEmbedField,
    DiscordEmbedFields, DiscordEmbedFooter,
    DiscordMessage, DiscordMessages
} from "@skyra/discord-components-react";
import * as React from "react";
import {EmbedManagerType} from "../../types/EmbedManagerType";
import {useUpdate} from "react-use";
import {useEffect} from "react";

function DiscordEmbedPreview(props: { embed: EmbedManagerType }) {
    const update = useUpdate()

    useEffect(() => {
        update()
    }, [props.embed, update])

    return (
        <div>
            <DiscordMessages>
                <DiscordMessage profile="Interception" author={"Interception"} verified avatar={"/logo.svg"} bot={true}
                                roleColor={"#70b7fa"}>
                    <DiscordEmbed
                        slot="embeds"
                        authorImage={props.embed.embed.author.icon}
                        authorName={props.embed.embed.author.name}
                        authorUrl={props.embed.embed.author.url}
                        color={props.embed.embed.color}
                        embedTitle={props.embed.embed.title}
                        image={props.embed.embed.image}
                        thumbnail={props.embed.embed.thumbnail}
                    >
                        <DiscordEmbedDescription slot="description">
                            {props.embed.embed.description}
                        </DiscordEmbedDescription>
                        <DiscordEmbedFields slot="fields">
                            {props.embed.embed.fields.map((embedField, index) => {
                                return (
                                    <DiscordEmbedField key={"embed-preview-field" + index} fieldTitle={embedField.name} inlineIndex={index + 1} inline={embedField.inline}>
                                        {embedField.value}
                                    </DiscordEmbedField>
                                )
                            })}
                        </DiscordEmbedFields>
                        <DiscordEmbedFooter
                            slot="footer"
                            footerImage={props.embed.embed.footer?.icon}
                        >
                            {props.embed.embed.footer?.text}
                        </DiscordEmbedFooter>
                    </DiscordEmbed>
                </DiscordMessage>
            </DiscordMessages>
        </div>
    )
}

export default DiscordEmbedPreview;
