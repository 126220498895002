import {AiOutlineCloseCircle} from "react-icons/ai";

function SaveButton(props: { onClick: () => void, close?: () => void, isOpen: boolean }) {
    return (
        <div
            className={`transition duration-300 flex items-center bg-light-blue fixed bottom-12 px-5 py-2 rounded left-1/2 -translate-x-1/2 ${props.isOpen ? "" : "translate-y-[calc(100vh_+_1rem)]"}`}
            id="save">
            {/*<AiOutlineCloseCircle className="cursor-pointer text-white" onClick={props.close}/>*/}
            <p className="prose prose-base text-white ml-2">Want to save your changes?</p>
            <div className="flex items-center ml-6">
                <button onClick={props.onClick}
                        type="submit"
                        className="prose prose-base text-white border-white border-2 rounded py-1 px-6 hover:bg-white hover:text-light-blue ease-in-out duration-150"
                        id="action-btn">Save
                </button>
            </div>
        </div>
    )
}

export default SaveButton;
