import * as React from "react";
import {AiOutlineCloseCircle, AiOutlinePlus} from "react-icons/ai";
import {InputTextField} from "../form-components/InputTextField";
import {FormSwitch} from "../form-components/FormSwitch";

function EmbedManagerFields(props: {
    fields: { name: string, value: string, inline: boolean }[],
    onAdd: () => void,
    onChange: (index: number, key: string, value: string | boolean) => void
    onRemove: (index: number) => void
}) {

    return (
        <div className="border-t-2 border-b-2 border-grayNew py-4">
            <div className="flex justify-between items-center">
                <h4 className="prose-base prose text-white">Fields</h4>
                <AiOutlinePlus className="cursor-pointer text-white" size={20} onClick={props.onAdd}/>
            </div>

            {props.fields.length !== 0 &&
                <div className="mt-4 flex flex-col justify-center gap-4">
                    {props.fields.map((field, index) => {
                        return (
                            <div key={"embed-field" + index} className="flex gap-4 items-center flex-wrap">
                                <AiOutlineCloseCircle onClick={() => props.onRemove(index)} className="text-white cursor-pointer"/>

                                <InputTextField
                                    id="name" label={"Name"} rows={1}
                                    value={field.name}
                                    onChange={(e) => props.onChange(index, "name", e.target.value)}/>
                                <InputTextField
                                    id="value" label={"Value"} rows={1}
                                    value={field.value}
                                    onChange={(e) => props.onChange(index, "value", e.target.value)}/>

                                <div>
                                    <p>Inline</p>
                                    <FormSwitch checked={field.inline}
                                                onChange={(_e, v) => props.onChange(index, "inline", v)}/>
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
}

export default EmbedManagerFields;
