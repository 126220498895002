import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";

export default function PageNotFound() {

    const navigate = useNavigate();

    return (
        <div className="grid place-items-center h-screen container">
            <div className="flex gap-6 flex-col justify-center items-center">
                <h1 className="text-[100px] text-white">4<span style={{color: "#1976D2"}}>0</span>4</h1>
                <p className="prose prose-2xl">Page not found :(</p>
                <Button size="large" onClick={() => navigate(-1)} variant="contained">go back</Button>
            </div>
        </div>
    )
}
