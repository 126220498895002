import {RiEditBoxFill} from "react-icons/ri";
import * as React from "react";
import {Link} from "react-router-dom";
import {FormSwitch} from "./form-components/FormSwitch";

function GeneralCard(props: {
    title: string,
    text: string,
    activated?: boolean | undefined,
    href?: string,
    onClick?: (event: any, value: boolean) => void
}) {
    return (
        <div
            className="bg-sidebar-black rounded min-w-[200px] w-[350px] max-w-[calc(100vw_-_2.5rem)] ease-in-out duration-500">
            {props.activated === undefined || props.onClick === undefined ?
                <div className={"block px-5 py-3"}>
                    <h4 className={"text-white prose font-semibold prose-xl"}>{props.title}</h4>
                    <p className="text-white opacity-50 prose prose-sm max-w-[330px] mt-2">{props.text}</p>
                </div>

                :
                <div className={"block px-5 py-3"}>
                    <div className="flex items-center justify-between">
                        <h4 className={"text-white prose font-semibold prose-xl"}>{props.title}</h4>
                        <FormSwitch checked={props.activated} onChange={props.onClick}/>
                    </div>
                    <p className="text-white opacity-50 prose prose-sm max-w-[330px] mt-2">{props.text}</p>
                </div>
            }
            {props.href === undefined ?
                <div
                    className={`mx-auto mt-3 py-2 text-center rounded-t-none rounded bg-grayNew`}>
                    <p className="prose-base text-white opacity-50">Not manageable</p>
                </div>
                :
                <Link to={props.href} className="hover:cursor-pointer">
                    <div
                        className={`mx-auto mt-3 py-2 flex items-center justify-center rounded-t-none rounded gap-2 bg-grayNew`}>
                        <RiEditBoxFill className="text-white" size={"1.25rem"}/>
                        <p className="prose-base text-white">Manage</p>
                    </div>
                </Link>
            }
        </div>
    )
}

export default GeneralCard;
